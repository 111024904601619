import React from 'react';

import './Loader.css';

/**
 * The animated loader component to display when something is processing to let the user wait.
 */
const Loader = () => {
    return (
        <div id="wz-lyriapay__loader" className="wz-lyriapay__loader">
            <span className="wz-lyriapay__loader__circle wz-lyriapay__loader__circle-1"></span>
            <span className="wz-lyriapay__loader__circle wz-lyriapay__loader__circle-2"></span>
            <span className="wz-lyriapay__loader__circle wz-lyriapay__loader__circle-3"></span>
            <span className="wz-lyriapay__loader__circle wz-lyriapay__loader__circle-4"></span>
        </div>
    );
};

Loader.displayName = 'Loader';

export default Loader;
