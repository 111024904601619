import React from 'react';

import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import FormField from 'lib/components/FormField';
import Icon from 'lib/components/Icon';
import './CvxInput.css';

/**
 * The goal of this component is to provide an input to enter the CVV or CVC of a credit card.
 */
const CvxInput = ({
    defaultCvx,
    onChange,
    disabled,
    forceDirty,
    length,
}) => {
    const { t } = useTranslation();

    const [textValue, setTextValue] = React.useState(defaultCvx);
    const [isDirty, setDirty] = React.useState(false);
    const [isFocused, setFocused] = React.useState(false);
    const [isValid, setValid] = React.useState(false);

    const minLength = Math.min(...length);
    const maxLength = Math.max(...length);

    const checkIsValid = React.useCallback(
        (value) => {
            return (
                maxLength === 0 ||
                (value.length >= minLength && value.length <= maxLength)
            );
        },
        [minLength, maxLength],
    );

    const tooltipContent = React.useMemo(
        () => {
            return (
                <div className="wz-lyriapay__cvx-input__tooltip-content">
                    <div className="wz-lyriapay__cvx-input__tooltip-content__item">
                        <Trans i18nKey="cvcTooltip" />
                    </div>
                    <div className="wz-lyriapay__cvx-input__tooltip-content__item">
                        <Icon id="cardExampleCvv" />
                    </div>
                    <div className="wz-lyriapay__cvx-input__tooltip-content__item">
                        <Icon id="amexCardExampleCvv" />
                    </div>
                </div>
            );
        },
        [],
    );

    const formatAndValidateCvx = React.useCallback(
        (value) => {
            let cvxCleanTextValue = value;
            let matches = value.match(/^[0-9]*$/g);
            cvxCleanTextValue = (matches && matches.join('')) || '';

            if (cvxCleanTextValue.length >= maxLength) {
                cvxCleanTextValue = cvxCleanTextValue.substring(0, maxLength);
            }
            return cvxCleanTextValue;
        },
        [maxLength],
    );

    const onChangeCvxInput = React.useCallback(
        (event, newValue) => {
            const valid = checkIsValid(newValue);
            setDirty(true);
            setValid(valid);
            setTextValue(newValue);
            if (onChange) {
                onChange({
                    value: newValue,
                    isValid: valid,
                });
            }
        },
        [onChange, checkIsValid],
    );

    // For some card types (bancontact), this input is not required, so we don't want to return it
    if (maxLength === 0) {
        return null;
    }

    return (
        <div id="wz-lyriapay__card-input__cvx-input">
            <FormField
                name='cvc'
                label={t('cvcLabel')}
                required
                shouldDisplayAsteriskIfRequired={false}
                autoComplete="cc-csc"
                pattern="\d*"
                value={textValue}
                disabled={disabled}
                maxLength={maxLength}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                onChange={onChangeCvxInput}
                formatAndValidate={formatAndValidateCvx}
                errors={
                    (isDirty || forceDirty) && !isValid && !isFocused
                        ? textValue === ''
                            ? [t('cvcEmpty')]
                            : [t('cvcInvalid')]
                        : null
                }
                TooltipProps={{
                    alt: t('cvcTooltipTrigger'),
                    iconClass: null,
                    children: tooltipContent,
                }}
                inputProps={{
                    inputMode: 'numeric',
                }}
            />
        </div>
    );
};

CvxInput.propTypes = {
    /**
     * The default value to put in the input for the cvx.
     */
    defaultCvx: PropTypes.string,
    /**
     * The callback function to trigger when the input is changed.
     * The function takes the arguments: value ({ month, year }), isValid.
     */
    onChange: PropTypes.func,
    /**
     * Whether the input must be disabled (just in read only) or not.
     */
    disabled: PropTypes.bool,
    /**
     * Whether we want to set the input to be dirty (that is to say as it has already been touched by the user).
     * That's mainly for error display purpose.
     */
    forceDirty: PropTypes.bool,
    /**
     * An array that contains the rules about the min length and the max length allowed for the input.
     */
    length: PropTypes.array,
};

CvxInput.defaultProps = {
    defaultCvx: '',
    onChange: null,
    disabled: false,
    forceDirty: false,
    length: [3, 4],
};

export default CvxInput;
