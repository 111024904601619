import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';

import './ApplePayButton.css';

let cachedScripts = {};

const loadScript = () => {
    const src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
    const existing = cachedScripts[src];
    if (existing) {
        return Promise.resolve();
    }
    const promise = new Promise((resolve, reject) => {
        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
            resolve();
        };
        const onScriptError = () => {
            cleanup();
            // Remove from cachedScripts so that we can try loading again
            delete cachedScripts[src];
            script.remove();
            reject(new Error(`Unable to load script ${src}`));
        };
        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);
        // Add script to document body
        document.body.appendChild(script);
        // Remove event listeners on cleanup
        function cleanup() {
            script.removeEventListener('load', onScriptLoad);
            script.removeEventListener('error', onScriptError);
        }
    });
    cachedScripts[src] = promise;
    return promise;
};

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Apple Pay button which is a particular integration of an existing PSP.
 * So, it's not really a PSP but just a kind of external card provider we will use with the 'classic' PSP.
 */
const ApplePayButton = ({
    config,
    merchantId,
    onLoadPaymentData,
    lyriaUrl,
    labelPayButton,
    lang,
    themeMode,
    isDisabled,
    appleMerchantId,
    appleMerchantName,
}) => {
    /* eslint-disable-next-line */
    const [applePayJSLoaded, setApplePayJSLoaded] = React.useState(false);
    const [applePayProcessing, setApplePayProcessing] = React.useState(false);
    const applePayButton = React.useRef();
    const ApplePaySession = 'ApplePaySession' in window ? window['ApplePaySession'] : null;

    React.useEffect(
        () => {
            loadScript().then(() => setApplePayJSLoaded(true));
        },
        [],
    );

    const handleDoPayment = React.useCallback(
        () => {
            if (isDisabled || !applePayJSLoaded || applePayProcessing) {
                return undefined;
            }
            setApplePayProcessing(true);
            let { version, request, merchantValidationUrl } = config;
            const session = new ApplePaySession(version, request);

            session.onvalidatemerchant = async (event) => {
                let merchantValidationPayload = {
                    url: event.validationURL,
                    merchant_id: merchantId,
                    domain: window.location.host,
                    request,
                };
                if (appleMerchantId) {
                    merchantValidationPayload['apple_merchant_id'] = appleMerchantId;
                }
                if (appleMerchantName) {
                    merchantValidationPayload['apple_merchant_name'] = appleMerchantName;
                }
                axios({
                    url: `${lyriaUrl}${merchantValidationUrl}`,
                    method: 'post',
                    data: merchantValidationPayload,
                }).then(({ data }) => {
                    // TODO: manage data.statusCode
                    session.completeMerchantValidation(data);
                }).catch(() => {
                    console.error('Impossible to validate merchant Apple Pay');
                    setApplePayProcessing(false);
                });
            };
            session.onpaymentauthorized = (event) => {
                onLoadPaymentData(event.payment.token).then((res) => {
                    if (res?.data.status === 'V') {
                        session.completePayment({
                            status: ApplePaySession.STATUS_SUCCESS,
                        });
                    } else {
                        session.completePayment({
                            status: ApplePaySession.STATUS_FAILURE,
                        });
                    }
                });
            };
            session.oncancel = (_event) => {
                // TODO forward error up
                setApplePayProcessing(false);
            };
            session.begin();
        },
        [
            ApplePaySession,
            applePayJSLoaded,
            applePayProcessing,
            config,
            isDisabled,
            lyriaUrl,
            merchantId,
            onLoadPaymentData,
            appleMerchantId,
            appleMerchantName,
        ],
    );

    React.useEffect(() => {
        const button = applePayButton.current;
        button.addEventListener('click', handleDoPayment);

        return () => button.removeEventListener('click', handleDoPayment);
    });

    React.useEffect(
        () => {
            let button = applePayButton.current;
            if (isDisabled && button) {
                button.style.opacity = 0.4;
            } else if (button) {
                button.style.opacity = 1;
            }
        },
        [isDisabled],
    );

    if (!ApplePaySession) {
        return;
    }

    const labelPayButtonMapping = {
        buy: 'buy',
        book: 'book',
        donate: 'donate',
        order: 'order',
        pay: 'pay',
        logoOnly: 'plain',
    };

    return (
        <div id="wz-lyripay__apple-pay-button">
            <apple-pay-button
                buttonstyle={themeMode === 'dark' ? 'white' : 'black'}
                type={labelPayButtonMapping[labelPayButton] || 'plain'}
                locale={lang}
                ref={applePayButton}
            />
        </div>
    );
};

ApplePayButton.propTypes = {
    /**
     * TODO
     */
    config: PropTypes.object,
    /**
     * TODO
     */
    merchantId: PropTypes.string,
    /**
     * TODO
     */
    onLoadPaymentData: PropTypes.func,
    /**
     * TODO
     */
    lyriaUrl: PropTypes.string,
    /**
     * The theme mode to display the appropriate version of the icon (if exist).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
    /**
     * The label of the 'pay' button to display. If logoOnly set, no label displayed.
     */
    labelPayButton: PropTypes.oneOf([
        'buy',
        'book',
        'donate',
        'order',
        'pay',
        'logoOnly',
    ]),
    /**
     * The language code to pass to Apple to display the label according to the user locale.
     */
    lang: PropTypes.string,
    /**
     * Whether the button is disabled, and must not trigger payment or not.
     */
    isDisabled: PropTypes.bool,
    /**
     * the apple merchant id, to be passed directly to the PSP for onvalidatemerchant call.
     * If not filled, lyria will use the merchantId, fetch the ApplePayMerchant in DB; and find the
     * ID on this object.
     */
    appleMerchantId: PropTypes.string,
    /**
     * the apple displayName, to be passed directly to the PSP for onvalidatemerchant call.
     * If not filled, lyria will use the merchantId, fetch the ApplePayMerchant in DB; and find the
     * displayName on this object.
     */
    appleMerchantName: PropTypes.string,
};

ApplePayButton.defaultProps = {
    themeMode: 'light',
    labelPayButton: 'buy',
    lang: 'en',
    isDisabled: false,
};

ApplePayButton.displayName = 'ApplePayButton';

export default ApplePayButton;
