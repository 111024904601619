import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { I18nextProvider, Trans, useTranslation } from 'react-i18next';

import ChileanForm from 'lib/components/ChileanForm';
import Clabe from 'lib/components/Clabe';
import ColombianForm from 'lib/components/ColombianForm';
import ErrorBlock from 'lib/components/ErrorBlock/ErrorBlock';
import Iban from 'lib/components/Iban';
import Ingenico from 'lib/components/Ingenico';
import IngenicoLegacy from 'lib/components/IngenicoLegacy';
import InternationalBank from 'lib/components/InternationalBank';
import Loader from 'lib/components/Loader';
import Payline from 'lib/components/Payline';
import PaypalForm from 'lib/components/PaypalForm';
import PaymentsOS from 'lib/components/PaymentsOS';
import PeruvianForm from '../PeruvianForm/PeruvianForm';
import PaymentMethodOption from 'lib/components/PaymentMethodOption';
import PaymentMethodOptionExpress from 'lib/components/PaymentMethodOptionExpress';
import SouthAfricanForm from 'lib/components/SouthAfricanForm';
import i18n from 'lib/config/i18n';
import { getClientCapabilities, lighenOrDarkenColor, getContrastText } from 'lib/utils/browserUtils';
import { intlPriceFormat } from 'lib/utils/currencyUtils';
import './LyriaPay.css';
import HongKongForm from '../HongKongForm/HongKongForm';

const importAdyen = () => import('lib/components/Adyen');
const Adyen = React.lazy(importAdyen);

/**
 * This is the main component that is exposed to be used by front-end projects that want to include payment providers.
 */
const LyriaPay = React.forwardRef(
    (
        {
            token,
            lyriaUrl,
            sandbox,
            hidePayButton,
            labelPayButton,
            labelPayButtonApplePayAndGooglePay,
            forceDisplayPaymentMethodsInExpandableButton,
            shouldDisplayExpressCheckoutTitle,
            shouldDisplayPaymentMethodsTitle,
            onPaymentResult,
            lang,
            loader,
            expandOptionIfOnlyOne,
            paymentCardInputWarningMessage,
            primaryColor,
            themeMode,
        },
        ref,
    ) => {

        if (typeof (lyria_debug) === 'undefined') {
            console.log = console.debug = console.info = function () { };
        }
        const [loading, setLoading] = React.useState(true);
        const [success, setSuccess] = React.useState(false);
        const [paymentMethods, setPaymentMethods] = React.useState([]);
        const [expandedPaymentMethodId, setExpandedPaymentMethodId] = React.useState(null);
        const [expandedPaymentMethodByDefaultDone, setExpandedPaymentMethodByDefaultDone] = React.useState(false);
        const [inProgressPaymentMethodId, setInProgressPaymentMethodId] = React.useState(null);
        const [errorCode, setErrorCode] = React.useState(null);
        const expandedPaymentMethodRef = React.useRef();
        const expressCheckoutAppleRef = React.useRef();
        const expressCheckoutGoogleRef = React.useRef();
        const [googleLoaded, setGoogleLoaded] = React.useState(false);
        const [googlePayAllowed, setGooglePayAllowed] = React.useState(false);

        const displaySpinner = loading || success;
        const paymentMethodsToDisplay = paymentMethods;

        const { t } = useTranslation();

        React.useEffect(() => {
            const googleLib = document.createElement('script');
            googleLib.src = 'https://pay.google.com/gp/p/js/pay.js';
            googleLib.async = true;
            googleLib.onload = () => {
                setGoogleLoaded(true);
            };
            document.head.appendChild(googleLib);
            return () => {
                document.head.removeChild(googleLib);
            };
        }, []);

        // preload adyen
        // it's a big chunk of code so we don't want to include it in the main js file
        // but we don't want to wait until we need to show the component either
        React.useEffect(() => {
            importAdyen();
        }, []);

        // handle both fr_FR and fr-FR
        React.useEffect(() => {
            i18n.changeLanguage(lang.replace('_', '-'));
        }, [lang]);

        /* Expose functions and props to external components */
        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return (
                        expandedPaymentMethodRef.current &&
                        expandedPaymentMethodRef.current.pay()
                    );
                },
                isAPaymentMethodExpanded: !!expandedPaymentMethodId,
                canClickOnPay: !!expandedPaymentMethodRef?.current?.canClickOnPay,
            }),
            [expandedPaymentMethodId],
        );

        /* Get the list of available payment methods from lyria backend according to the input token */
        React.useEffect(() => {
            setLoading(true);
            if (!token || !lyriaUrl) {
                console.error('"token" and "lyriaUrl" are required');
                return;
            }

            setErrorCode(null);
            axios({
                method: 'post',
                url: `${lyriaUrl}/paymentConfig`,
                data: {
                    request_token: token,
                    client_capabilities: getClientCapabilities(),
                },
            })
                .then((paymentConfigData) => {
                    setPaymentMethods(paymentConfigData.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                    setErrorCode('internal');
                });
        }, [token, lyriaUrl]);

        React.useEffect(() => {
            if (!googleLoaded) {
                setGooglePayAllowed(false);
                return;
            }
            let googlepayPaymentMethod = paymentMethods.filter((paymentMethod) => ['google_pay'].includes(paymentMethod.config?.type));
            if (googlepayPaymentMethod.length === 0) {
                setGooglePayAllowed(false);
                console.log('no gpaymethod');
                return;
            }
            if (googlepayPaymentMethod.length > 1) {
                console.log('too many google pay providers');
            }
            let gpayPaymentMethod = googlepayPaymentMethod[0];

            if (!gpayPaymentMethod.config?.config) {
                setGooglePayAllowed(false);
                console.log('no config for this payment method');
                return;
            }

            const { environment, ...request } = gpayPaymentMethod.config.config.request;
            if (!request.allowedPaymentMethods || request.allowedPaymentMethods.length === 0) {
                console.log('no allowed gpay methods');
                return null;
            }
            let allowedGooglePayMethods = request.allowedPaymentMethods.map((element) => {
                return element.type;
            });
            // eslint-disable-next-line
            const paymentsClient = new google.payments.api.PaymentsClient({ environment: environment });
            paymentsClient.isReadyToPay({ allowedPaymentMethods: allowedGooglePayMethods }).then(
                (response) => {
                    if (response.result) {
                        setGooglePayAllowed(true);
                    } else {
                        setGooglePayAllowed(false);
                    }
                })
                .catch(function (_err) {
                    setGooglePayAllowed(false);
                },
                );
        }, [googleLoaded, paymentMethods]);


        const onErrorsReloadPaymentConfig = React.useCallback(
            (response) => {
                if (!['RetryWithTheSameCard', 'RetryWithAnotherCard', 'CheckYourCardDetails', 'AmountLimit', 'PSPError', 'PSPAPIError'].includes(response?.code)) {
                    return;
                }
                axios({
                    method: 'post',
                    url: `${lyriaUrl}/paymentConfig`,
                    data: {
                        request_token: token,
                        client_capabilities: getClientCapabilities(),
                    },
                })
                    .then((paymentConfigData) => {
                        setPaymentMethods(paymentConfigData.data);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        setErrorCode('internal');
                    });
            },
            [token, lyriaUrl],
        );


        const onClickPaymentMethodOption = React.useCallback(
            (paymentMethod) => (expanded) => {
                // We lock the expanded button to not be expandable or collapsable until payment process finished.
                if (inProgressPaymentMethodId) {
                    return null;
                }
                if (expanded) {
                    setExpandedPaymentMethodId(paymentMethod.id);
                } else {
                    setExpandedPaymentMethodId(null);
                }
            },
            [inProgressPaymentMethodId],
        );

        const paymentResultCallback = React.useCallback(
            (res) => {
                if (res.success) {
                    /* If the payment successes we display a loader because we don't want the user
                    to see the payment form again until the host redirects to its own success page */
                    setErrorCode(null);
                    setSuccess(true);
                } else {
                    setErrorCode(res.data?.code);
                    setSuccess(false);
                    /* If the payment failed we want to show the other psps expand buttons again if any
                    to allow the user to select another payment method if needed */
                    setInProgressPaymentMethodId(null);
                }
                onPaymentResult(res);
            },
            [onPaymentResult],
        );

        const paymentStartedCallback = React.useCallback(
            (paymentMethod) => () => {
                setErrorCode(null);
                setInProgressPaymentMethodId(paymentMethod.id);
            },
            [],
        );

        const getAdyenComponent = React.useCallback(
            (paymentMethod, isExpressApple = false, isExpressGoogle = false) => {
                // todo : filter to display only one adyen payment method, not a dozen since this must be either
                // Gp or apple pay
                let adyenRef;
                switch (true) {
                    case isExpressApple:
                        adyenRef = expressCheckoutAppleRef;
                        break;
                    case isExpressGoogle:
                        adyenRef = expressCheckoutGoogleRef;
                        break;
                    default:
                        adyenRef = expandedPaymentMethodRef;
                }
                let expressCheckout = isExpressApple || isExpressGoogle;
                return (
                    <React.Suspense fallback={<>{loader}</>}>
                        <Adyen
                            config={paymentMethod.config}
                            sandbox={sandbox}
                            token={token}
                            lyriaUrl={lyriaUrl}
                            onPaymentStarted={paymentStartedCallback(paymentMethod)}
                            onPaymentResult={paymentResultCallback}
                            hidePayButton={hidePayButton}
                            labelPayButton={labelPayButton}
                            labelPayButtonApplePayAndGooglePay={labelPayButtonApplePayAndGooglePay}
                            ref={adyenRef}
                            loader={loader}
                            savePaymentMethod={paymentMethod.save_payment_method}
                            savedPaymentMethods={paymentMethod.saved_payment_methods}
                            paymentCardInputWarningMessage={expressCheckout ? false : paymentCardInputWarningMessage}
                            operation={paymentMethod.operation}
                            paymentMethods={paymentMethod.payment_methods}
                            lang={lang}
                            themeMode={themeMode}
                        />
                    </React.Suspense>
                );
            },
            [
                hidePayButton,
                labelPayButton,
                loader,
                lyriaUrl,
                paymentCardInputWarningMessage,
                paymentResultCallback,
                paymentStartedCallback,
                sandbox,
                token,
                lang,
                themeMode,
                labelPayButtonApplePayAndGooglePay,
            ],
        );

        let paymentMethodsToDisplayInOption = paymentMethodsToDisplay;
        let paymentMethodsToDisplayInExpressCheckout = [];
        /* Here filter the payment methods that should appear in a dedicated 'express checkout' section */
        if (!forceDisplayPaymentMethodsInExpandableButton) {
            paymentMethodsToDisplayInOption = paymentMethodsToDisplay.filter((paymentMethod) => !['apple_pay', 'google_pay'].includes(paymentMethod.config?.type));
            paymentMethodsToDisplayInExpressCheckout = paymentMethodsToDisplay
                .filter((paymentMethod) => ['apple_pay', 'google_pay'].includes(paymentMethod.config?.type))
                .map((paymentMethod, _index, _array) => {
                    const isPaymentMethodOptionApplePay = paymentMethod.config?.type === 'apple_pay';
                    // eslint-disable-next-line
                    const isPaymentMethodOptionGooglePay = paymentMethod.config?.type === 'google_pay';
                    let isSecureContextValue = true;
                    try {
                        isSecureContextValue = window.isSecureContext || isSecureContext;
                    } catch (e) {
                        // That means the browser does not support it
                    }
                    if (isPaymentMethodOptionApplePay) {
                        const ApplePaySession = 'ApplePaySession' in window ? window['ApplePaySession'] : null;
                        const isInIframe = window.location !== window.top.location;
                        if (!ApplePaySession || isInIframe) {
                            return null;
                        }
                    } else if (isPaymentMethodOptionGooglePay) {
                        if (!isSecureContextValue) {
                            return null;
                        }
                        if (!googlePayAllowed) {
                            return null;
                        }
                    }
                    switch (paymentMethod.psp) {
                        case 'payline':
                            return (
                                <Payline
                                    labelPayButton={labelPayButton}
                                    labelPayButtonApplePayAndGooglePay={labelPayButtonApplePayAndGooglePay}
                                    config={paymentMethod.config}
                                    lang={lang}
                                    sandbox={sandbox}
                                    token={token}
                                    lyriaUrl={lyriaUrl}
                                    onPaymentStarted={paymentStartedCallback(paymentMethod)}
                                    onPaymentResult={paymentResultCallback}
                                    hidePayButton={hidePayButton}
                                    loader={loader}
                                    savePaymentMethod={paymentMethod.save_payment_method}
                                    savedPaymentMethods={paymentMethod.saved_payment_methods}
                                    operation={paymentMethod.operation}
                                    themeMode={themeMode}
                                    onPaymentError={onErrorsReloadPaymentConfig}
                                    isDisabled={(
                                        !!inProgressPaymentMethodId
                                        && inProgressPaymentMethodId !== paymentMethod.id
                                    )}
                                />
                            );
                        case 'adyen':
                            // TODO remove this if to integrate this into the «if» block before the switch
                            // then test that nothing got broken
                            if (isPaymentMethodOptionApplePay || isPaymentMethodOptionGooglePay) {
                                return getAdyenComponent(paymentMethod, isPaymentMethodOptionApplePay, isPaymentMethodOptionGooglePay);
                            } else {
                                return null;
                            }
                        default:
                            return null;
                    }
                })
                .filter((paymentMethodComponent) => !!paymentMethodComponent);
        }

        const isExpressCheckoutAvailable = paymentMethodsToDisplayInExpressCheckout.length > 0;

        /* By default, expand the button if there is only one payment method available */
        React.useEffect(
            () => {
                const paymentMethodsThatCanBeExpanded = paymentMethodsToDisplay?.filter((pm) => !['google_pay', 'apple_pay'].includes(pm.config?.type));
                if (expandOptionIfOnlyOne && paymentMethodsThatCanBeExpanded.length === 1 && !expandedPaymentMethodByDefaultDone) {
                    if (!paymentMethodsThatCanBeExpanded[0].is_disabled && !isExpressCheckoutAvailable) {
                        setExpandedPaymentMethodId(paymentMethodsThatCanBeExpanded[0].id);
                    }
                    setExpandedPaymentMethodByDefaultDone(true);
                }
            },
            [
                expandOptionIfOnlyOne,
                expandedPaymentMethodByDefaultDone,
                paymentMethodsToDisplay,
                isExpressCheckoutAvailable,
            ],
        );

        return (
            <I18nextProvider i18n={i18n}>
                <link
                    href="https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@400;500;700&display=swap"
                    rel="stylesheet"
                />
                <style>
                    {
                        `:root {
                            --primary: ${primaryColor};
                            --primary-dark: ${lighenOrDarkenColor(primaryColor, 0.3)};
                            --primary-light: ${lighenOrDarkenColor(primaryColor, -0.6)};
                            --primary-background: ${lighenOrDarkenColor(primaryColor, -0.9)};
                            --primary-contrast-text: ${getContrastText(primaryColor)};
                            --background-main: ${themeMode === 'dark' ? '#222222' : '#ffffff'};
                            --background-main-disabled: ${themeMode === 'dark' ? '#444444' : '#eeeeee'};
                            --background-card: ${themeMode === 'dark' ? '#393939' : '#f6f6f6'};
                            --background-input: ${themeMode === 'dark' ? '#444444' : '#eeeeee'};
                            --text-main: ${themeMode === 'dark' ? '#ffffff' : '#000000'};
                        }`
                    }
                </style>
                {sandbox && <h2>Sandbox mode</h2>}
                <div id="wz-lyriapay__lyria-pay">
                    {errorCode && (
                        <ErrorBlock errorCode={errorCode} />
                    )}
                    {displaySpinner && (
                        <div className="wz-lyriapay__lyria-pay__loader">
                            {loader}
                        </div>
                    )}
                    {!displaySpinner && isExpressCheckoutAvailable && (
                        <div className="wz-lyriapay__lyria-pay__express_checkout">
                            <PaymentMethodOptionExpress
                                shouldDisplayExpressCheckoutTitle={shouldDisplayExpressCheckoutTitle}
                                paymentMethodComponents={paymentMethodsToDisplayInExpressCheckout}
                            />
                        </div>
                    )}
                    {!displaySpinner && paymentMethodsToDisplayInOption?.length > 0 && (
                        <div className="wz-lyriapay__lyria-pay__regular_checkout">
                            {shouldDisplayPaymentMethodsTitle && (
                                <div className="wz-lyriapay__lyria-pay__regular_checkout__label">
                                    {t('paymentMethodOptionRegularLabel')}
                                </div>
                            )}
                            {paymentMethodsToDisplayInOption.map((paymentMethod, index, array) => {
                                let paymentMethodComponent;
                                let paymentMethodOoptionIsCreditCard = paymentMethod.payment_methods.every(p => !!p.is_card);
                                let paymentMethodOptionName = (paymentMethodOoptionIsCreditCard && paymentMethod.payment_methods?.length > 1)
                                    ? t('paymentMethodOptionCreditCard')
                                    : paymentMethod.payment_methods.map(p => p.label).join(' / ');
                                // the payment method labels will be displayed if any payment_method has not «is_card» set to true,
                                // or if there is just one payment method
                                let paymentMethodOptionIcons = paymentMethod.payment_methods.map(p => p.payment_method);
                                let paymentMethodIsComponentOnly = false;
                                const isPaymentMethodOptionApplePay = paymentMethod.config?.type === 'apple_pay';
                                const isPaymentMethodOptionGooglePay = paymentMethod.config?.type === 'google_pay';
                                switch (paymentMethod.psp) {
                                    case 'payline':
                                        if (isPaymentMethodOptionGooglePay) {
                                            paymentMethodOptionName = 'Google Pay';
                                            paymentMethodOptionIcons = ['GooglePay'];
                                            let isSecureContextValue = true;
                                            try {
                                                isSecureContextValue = window.isSecureContext || isSecureContext;
                                            } catch (e) {
                                                // That means the browser does not support it
                                            }
                                            if (!isSecureContextValue) {
                                                return null;
                                            }
                                        } else if (isPaymentMethodOptionApplePay) {
                                            const ApplePaySession = 'ApplePaySession' in window ? window['ApplePaySession'] : null;
                                            const isInIframe = window.location !== window.top.location;
                                            if (!ApplePaySession || isInIframe) {
                                                return null;
                                            }
                                            paymentMethodOptionName = 'Apple Pay';
                                            paymentMethodOptionIcons = ['ApplePay'];
                                        }
                                        paymentMethodComponent = (
                                            <Payline
                                                labelPayButton={labelPayButton}
                                                labelPayButtonApplePayAndGooglePay={labelPayButtonApplePayAndGooglePay}
                                                lang={lang}
                                                config={paymentMethod.config}
                                                sandbox={sandbox}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentStarted={paymentStartedCallback(paymentMethod)}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                ref={isPaymentMethodOptionApplePay || isPaymentMethodOptionGooglePay ? undefined : expandedPaymentMethodRef}
                                                loader={loader}
                                                savePaymentMethod={paymentMethod.save_payment_method}
                                                savedPaymentMethods={paymentMethod.saved_payment_methods}
                                                paymentCardInputWarningMessage={paymentCardInputWarningMessage}
                                                operation={paymentMethod.operation}
                                                themeMode={themeMode}
                                                onPaymentError={onErrorsReloadPaymentConfig}
                                                isDisabled={(
                                                    !!inProgressPaymentMethodId
                                                    && inProgressPaymentMethodId !== paymentMethod.id
                                                )}
                                            />
                                        );
                                        break;
                                    case 'adyen':
                                        paymentMethodIsComponentOnly = (
                                            array.length === 1
                                            && paymentMethod.operation === 'payment'
                                        );
                                        paymentMethodComponent = getAdyenComponent(paymentMethod);
                                        break;
                                    case 'paymentsos':
                                        paymentMethodComponent = (
                                            <React.Suspense fallback={<>{loader}</>}>
                                                <PaymentsOS
                                                    config={paymentMethod.config}
                                                    token={token}
                                                    lyriaUrl={lyriaUrl}
                                                    savePaymentMethod={paymentMethod.save_payment_method}
                                                    savedPaymentMethods={paymentMethod.saved_payment_methods}
                                                    onPaymentStarted={paymentStartedCallback(paymentMethod)}
                                                    onPaymentResult={paymentResultCallback}
                                                    loader={loader}
                                                    hidePayButton={hidePayButton}
                                                    labelPayButton={labelPayButton}
                                                    paymentCardInputWarningMessage={paymentCardInputWarningMessage}
                                                    themeMode={themeMode}
                                                    onPaymentError={onErrorsReloadPaymentConfig}
                                                    ref={expandedPaymentMethodRef}
                                                />
                                            </React.Suspense>
                                        );
                                        break;
                                    case 'iban':
                                        paymentMethodComponent = (
                                            <Iban
                                                pspId={paymentMethod.id}
                                                currency={paymentMethod.currency}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                config={paymentMethod.config}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'chilean_form':
                                        paymentMethodComponent = (
                                            <ChileanForm
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'clabe':
                                        paymentMethodComponent = (
                                            <Clabe
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'colombian_form':
                                        paymentMethodComponent = (
                                            <ColombianForm
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'international_bank':
                                        paymentMethodComponent = (
                                            <InternationalBank
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'paypal':
                                        paymentMethodComponent = (
                                            <PaypalForm
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'peruvian_form':
                                        paymentMethodComponent = (
                                            <PeruvianForm
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'ingenico':
                                        paymentMethodIsComponentOnly = false;
                                        paymentMethodComponent = (
                                            <Ingenico
                                                config={paymentMethod.config}
                                                lyriaUrl={lyriaUrl}
                                                token={token}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                paymentMethods={paymentMethod.payment_methods}
                                                themeMode={themeMode}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'ingenico_legacy':
                                        paymentMethodIsComponentOnly = false;
                                        paymentMethodComponent = (
                                            <IngenicoLegacy
                                                config={paymentMethod.config}
                                                lyriaUrl={lyriaUrl}
                                                token={token}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                paymentMethods={paymentMethod.payment_methods}
                                                themeMode={themeMode}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'south_african_form':
                                        paymentMethodComponent = (
                                            <SouthAfricanForm
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    case 'hong_kong_form':
                                        paymentMethodComponent = (
                                            <HongKongForm
                                                pspId={paymentMethod.id}
                                                token={token}
                                                lyriaUrl={lyriaUrl}
                                                onPaymentResult={paymentResultCallback}
                                                hidePayButton={hidePayButton}
                                                labelPayButton={labelPayButton}
                                                ref={expandedPaymentMethodRef}
                                            />
                                        );
                                        break;
                                    default:
                                        paymentMethodComponent = null;
                                }

                                return (
                                    <div
                                        key={`payment_method_option_${paymentMethod.id}`}
                                        className="wz-lyriapay__lyria-pay__regular_checkout__option"
                                    >
                                        <PaymentMethodOption
                                            shouldHideTheExpandableButtonWhenOpen={false}
                                            name={paymentMethodOptionName}
                                            icons={paymentMethodOptionIcons}
                                            operationType={paymentMethod.operation}
                                            isDisabled={paymentMethod.is_disabled || !!inProgressPaymentMethodId}
                                            paymentMethods={paymentMethod.payment_methods}
                                            feesAmount={paymentMethod?.fee ? intlPriceFormat(paymentMethod.fee, 2, paymentMethod.currency, lang) : null}
                                            minimumAmount={intlPriceFormat(paymentMethod.min_amount_for_credit_required, 2, paymentMethod.currency, lang)}
                                            paymentMethodComponent={paymentMethodComponent}
                                            onClick={onClickPaymentMethodOption(paymentMethod)}
                                            isSelected={paymentMethod.id === expandedPaymentMethodId || paymentMethod.id === inProgressPaymentMethodId}
                                            isComponentOnly={paymentMethodIsComponentOnly}
                                            themeMode={themeMode}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!displaySpinner && !errorCode && !paymentMethodsToDisplay.length && (
                        <div className="wz-lyriapay__lyria-pay__no-payment-method-available">
                            <Trans i18nKey="no_payment_method_available" />
                        </div>
                    )}
                </div>
            </I18nextProvider>
        );
    },
);

LyriaPay.propTypes = {
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string.isRequired,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the payment process should be processed in a sandbox (for testing purpose).
     */
    sandbox: PropTypes.bool,
    /**
     * Whether the submit button must be displayed or not.
     * If true, it should be handle outside the LyriaPay component thanks to the 'ref' props.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The label of the 'pay' button to display for GogglePay and ApplePay.
     */
    labelPayButtonApplePayAndGooglePay: PropTypes.oneOf([
        'buy',
        'book',
        'donate',
        'order',
        'pay',
        'logoOnly',
    ]),
    /**
     * Whether we want to force all payment methods to be displayed in an expandable button.
     * This is especially for GooglePay and ApplePay.
     */
    forceDisplayPaymentMethodsInExpandableButton: PropTypes.bool,
    /**
     * Whether we want to display the title about express payment method ('express checkout') above the related section.
     */
    shouldDisplayExpressCheckoutTitle: PropTypes.bool,
    /**
     * Whether we want to display the title about regular payment method ('payment methods') above the related section.
     */
    shouldDisplayPaymentMethodsTitle: PropTypes.bool,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
    /**
     * The language code to use to translate all the labels of all the components.
     */
    lang: PropTypes.string,
    /**
     * The loader component to use when the payment or drop-in is loading.
     */
    loader: PropTypes.node,
    /**
     * Whether we want to expand the option by default if there is only one available.
     */
    expandOptionIfOnlyOne: PropTypes.bool,
    /**
     * A warning message to display for payment at the card input step (mainly to advise the user to save its payment method).
     */
    paymentCardInputWarningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * The primary color to use in the whole component (especially for buttons).
     */
    primaryColor: PropTypes.string,
    /**
     * The theme to use (dark or light).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
};

LyriaPay.defaultProps = {
    sandbox: false,
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label in the different components
    labelPayButtonApplePayAndGooglePay: 'buy',
    forceDisplayPaymentMethodsInExpandableButton: false,
    shouldDisplayExpressCheckoutTitle: true,
    shouldDisplayPaymentMethodsTitle: true,
    lang: 'en',
    loader: <Loader />,
    expandOptionIfOnlyOne: true,
    paymentCardInputWarningMessage: null,
    primaryColor: '#0032fa',
    themeMode: 'light',
};

LyriaPay.displayName = 'LyriaPay';

export default LyriaPay;
