import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import ButtonLink from 'lib/components/ButtonLink';
import Icon from 'lib/components/Icon';
import Loader from 'lib/components/Loader';
import SavedCardInput from 'lib/components/SavedCardInput';
import { formatExpiryDate } from 'lib/utils/cardUtils';
import './Ingenico.css';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Ingenico PSP integration that can deal with multiple bank card types.
 */
const Ingenico = React.forwardRef(
    (
        {
            config,
            token,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            paymentMethods, // eslint-disable-line no-unused-vars
            onPaymentResult,
            themeMode,
        },
        ref,
    ) => {
        /* ------------ Initialization of the imports, the variables and the states ------------ */

        // Hooks
        const { t } = useTranslation();

        const [isReady, setIsReady] = React.useState(false);

        /* ------------ The main functions ------------ */

        // The api call to Lyria (backend) to trigger Ingenico payment process.
        const processPayment = React.useCallback(
            (urlToRedirect) => {
                axios({
                    method: 'post',
                    url: lyriaUrl + '/ingenico/process',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        request_token: token,
                        psp: config.id,
                    },
                })
                    .then((_res) => {
                        window.top.location.href = urlToRedirect;
                        setIsReady(true);
                    })
                    .catch((error) => {
                        if (onPaymentResult) {
                            onPaymentResult({ success: false, data: { code: 'internal' }});
                        }
                        console.info(error);
                    });
            },
            [config.id, onPaymentResult, token, lyriaUrl],
        );

        /* ------------ The side effects and imperative handlers ------------ */

        // The handler to be able to external component to trigger 'pay' button actions.
        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    let urlToRedirect = config.no_card_hosted_checkout_url;
                    if (config.saved_payment_methods_config?.length) {
                        urlToRedirect = config.saved_payment_methods_config[0].url;
                    }
                    processPayment(urlToRedirect);
                },
                canClickOnPay: false,
            }),
            [config.no_card_hosted_checkout_url, config.saved_payment_methods_config, processPayment],
        );

        // Redirect immediately to Ingenico checkout page if there is no payment method saved and no .
        React.useEffect(
            () => {
                if (!config.saved_payment_methods_config?.length && hidePayButton) {
                    processPayment(config.no_card_hosted_checkout_url);
                } else {
                    setIsReady(true);
                }
            },
            [config.no_card_hosted_checkout_url, config.saved_payment_methods_config, processPayment, hidePayButton],
        );

        /* ------------ The JSX to return according to the logic above ------------ */
        if (!isReady) {
            return (
                <div id="wz-lyriapay__ingenico">
                    <Loader />
                </div>
            );
        }

        if (!config.saved_payment_methods_config?.length && !hidePayButton) {
            return (
                <div id="wz-lyriapay__ingenico">
                    <div className="wz-lyriapay__ingenico__label">
                        {t('ingenico_label_checkout', { label: labelPayButton || t('payButton') })}
                    </div>
                    <div className="wz-lyriapay__common__psp__submit">
                        <Button
                            onClick={() => processPayment(config.no_card_hosted_checkout_url)}
                            label={labelPayButton || t('payButton')}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div id="wz-lyriapay__ingenico">
                {!!config.saved_payment_methods_config?.length && config.saved_payment_methods_config.map((spmc) => (
                    <div className="wz-lyriapay__ingenico__saved-payment-method" key={spmc.uuid}>
                        <SavedCardInput
                            uuid={spmc.uuid}
                            onClickRadio={() => processPayment(spmc.url)}
                            maskedCardNumber={spmc.masked_number}
                            expiryDate={formatExpiryDate(spmc.expiry)}
                            cardType={spmc.card_type}
                        />
                    </div>
                ))}
                <div className="wz-lyriapay__ingenico__new-payment-method-button">
                    <ButtonLink
                        onClick={() => processPayment(config.no_card_hosted_checkout_url)}
                        label={t('useNewPaymentMethod')}
                        startIcon={<Icon id="add" themeMode={themeMode} />}
                    />
                </div>
            </div>
        );
    },
);

Ingenico.propTypes = {
    /**
     * The specific configuration related to this payment method (IBAN).
     */
    config: PropTypes.object,
    /**
     * The token to pass to call the back-end.
     */
    token: PropTypes.string.isRequired,
    /**
     * The url of lyria, the payment back-end.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The list of payment methods that belongs to the given payment method. That's a kind of sub payment method.
     * For example, for 'credit card' payment method we could have Visa, Mastercard and so on.
     */
    paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            payment_method: PropTypes.string.isRequired,
        }),
    ),
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
    /**
     * The theme to use (dark or light).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
};

Ingenico.defaultProps = {
    config: {},
    hidePayButton: false,
    paymentMethods: [],
    onPaymentResult: null,
    themeMode: 'light',
};

Ingenico.displayName = 'Ingenico';

export default Ingenico;
