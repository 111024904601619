import React from 'react';

import axios from 'axios';
import LyriaPay from 'lib';

import DevExpandableBlock from 'dev/components/DevExpandableBlock';
import './DevIntegration.css';

/**
 * This file is just a component for development purposes,
 * the LyriaPay's react components are located in the 'lib' subdirectory.
 */
const DevIntegration = () => {
    const integrationRef = React.useRef();
    /* The states related to the LyriaPay options */
    const [lyriaUrl, setLyriaUrl] = React.useState(
        window.location.protocol === 'https:'
            ? `${window.location.origin}`
            : `http://${window.location.hostname}:8003`,
    );
    const [token, setToken] = React.useState('');
    const [labelPayButton, setLabelPayButton] = React.useState('');
    const [labelPayButtonApplePayGooglePay, setLabelPayButtonApplePayGooglePay] = React.useState('buy');
    const [customLoader, setCustomLoader] = React.useState('');
    const [lang, setLang] = React.useState('fr');
    const [hidePayButton, setHidePayButton] = React.useState(false);
    const [darkMode, setDarkMode] = React.useState(false);
    const [primaryColor, setPrimaryColor] = React.useState('#0032fa');
    const [expandOptionIfOnlyOne, setExpandOptionIfOnlyOne] = React.useState(true);
    const [paymentCardInputWarningMessage, setPaymentCardInputWarningMessage] = React.useState('');
    const [forceDisplayPaymentMethodsInExpandableButton, setForceDisplayPaymentMethodsInExpandableButton] = React.useState(false);
    const [shouldDisplayExpressCheckoutTitle, setShouldDisplayExpressCheckoutTitle] = React.useState(true);
    const [shouldDisplayPaymentMethodsTitle, setShouldDisplayPaymentMethodsTitle] = React.useState(true);
    /* The states related to the LyriaPay interaction (return) */
    const [hostPayButtonDisabled, setHostPayButtonDisabled] = React.useState(false);
    const [lastPaymentResult, setLastPaymentResult] = React.useState(null);
    /* The states related to the token (to generate if we don't copy past on the text area) */
    const [amount, setAmount] = React.useState(5000);
    const [service, setService] = React.useState('gill');
    const [currency, setCurrency] = React.useState('EUR');
    const [tokenType, setTokenType] = React.useState('payment');

    const handleRedirect = () => {
        localStorage.removeItem('token');
        localStorage.setItem('token', token);
        window.location.href = '/';
    };

    const fetchToken = React.useCallback(
        () => {
            const source = axios.CancelToken.source();
            service && axios
                .post(
                    `${lyriaUrl}/mktoken/`,
                    { issuer: service, amount, currency, lyriaUrl, sub_type: tokenType },
                )
                .then((res) => {
                    setToken(res.data.replaceAll('\n', '').replaceAll(' ', ''));
                });
            return () => source.cancel();
        },
        [service, lyriaUrl, amount, currency, tokenType],
    );

    const fillValue = (id, value = '') => {
        const elm = document.getElementById(id);
        if (elm) {
            const valueSetter = Object.getOwnPropertyDescriptor(elm.__proto__, 'value').set;
            valueSetter.call(elm, value);
            elm.dispatchEvent(new Event('input', { bubbles: true }));
        }
    };

    const autoFill = () => {
        fillValue('cardnumber', '4970105191919435'); // default payline visa frictionless
        fillValue('expirydate', '01/30');
        fillValue('cvc', '123');
    };

    return (
        <div id="wz-lyriapay__dev-integration">
            <div className="wz-lyriapay__dev-integration__title">
              Lyria DEV testing plateform
            </div>
            {process.env.REACT_APP_PAYMENT_PAGE && token &&
                <button onClick={handleRedirect}>PAY</button>
            }
            <div className="wz-lyriapay__dev-integration__tools">
                <DevExpandableBlock
                    header="Dev tools, utils and links"
                >
                    <div className="wz-lyriapay__dev-integration__tools__links">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.monext.fr/display/DT/Les+cartes+de+test#Lescartesdetest-Carte3DSV2France"
                        >
                            Payline test cards
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.adyen.com/development-resources/test-cards/test-card-numbers"
                        >
                            Adyen test cards
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://developer.apple.com/apple-pay/sandbox-testing/"
                        >
                            Apple test cards
                        </a>
                        <a
                            href="#_"
                            onClick={autoFill}
                        >
                            Auto-Fill
                        </a>
                    </div>
                </DevExpandableBlock>
            </div>
            <div className="wz-lyriapay__dev-integration__token">
                <DevExpandableBlock
                    header="Customized token generation"
                >
                    <div className="wz-lyriapay__dev-integration__field-group">
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Issuer
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <select onChange={(e) => setService(e.target.value)}>
                                    <option value="gill">gill</option>
                                    <option value="menhir">menhir</option>
                                </select>
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Currency
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <select onChange={(e) => setTokenType(e.target.value)}>
                                    <option value="payment">Payment</option>
                                    <option value="credit">Credit</option>
                                </select>
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Currency
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <select onChange={(e) => setCurrency(e.target.value)}>
                                    <option value="EUR">EUR</option>
                                    <option value="CHF">CHF</option>
                                    <option value="ZAR">ZAR</option>
                                </select>
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Issuer
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <input
                                    type="number"
                                    value={amount / 100}
                                    onChange={(e) => setAmount(e.target.value * 100)}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <button
                                    disabled={hostPayButtonDisabled}
                                    onClick={() => {
                                        fetchToken();
                                    }}
                                >
                                    GENERATE TOKEN
                                </button>
                            </div>
                        </div>
                    </div>
                </DevExpandableBlock>
            </div>
            <div className="wz-lyriapay__dev-integration__options">
                <DevExpandableBlock
                    header="Lyria Pay Settings"
                    expanded
                >
                    <div className="wz-lyriapay__dev-integration__field-group">
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Lyria URL (backend)*
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setLyriaUrl(e.target.value);
                                    }}
                                    value={lyriaUrl}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Token*
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <textarea
                                    rows="4"
                                    placeholder="Lyria token"
                                    onChange={(e) => {
                                        setToken(e.target.value.replaceAll('\n', '').replaceAll(' ', ''));
                                        setLastPaymentResult(null);
                                    }}
                                    value={token || ''}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Label of &apos;Pay&apos; button
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setLabelPayButton(e.target.value);
                                    }}
                                    value={labelPayButton}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Label of &apos;Pay&apos; button (Google Pay & Apple Pay)
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <select
                                    onChange={(e) => setLabelPayButtonApplePayGooglePay(e.target.value)}
                                    value={labelPayButtonApplePayGooglePay}
                                >
                                    <option value="buy">Buy</option>
                                    <option value="book">Book</option>
                                    <option value="donate">Donate</option>
                                    <option value="order">Order</option>
                                    <option value="pay">Pay</option>
                                    <option value="test">Unknow label</option>
                                    <option value="logoOnly">No label</option>
                                </select>
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Primary color
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setPrimaryColor(e.target.value);
                                    }}
                                    value={primaryColor}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Customized loader (replaced by text for this test)
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setCustomLoader(e.target.value);
                                    }}
                                    value={customLoader}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                A warning message to display at card input step
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setPaymentCardInputWarningMessage(e.target.value);
                                    }}
                                    value={paymentCardInputWarningMessage}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field">
                            <div className="wz-lyriapay__dev-integration__field__label">
                                Locale
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input">
                                <select
                                    onChange={(e) => setLang(e.target.value)}
                                    value={lang}
                                >
                                    <option value="ca">ca</option>
                                    <option value="ca-ES">ca-ES</option>
                                    <option value="de">de</option>
                                    <option value="de-DE">de-DE</option>
                                    <option value="en">en</option>
                                    <option value="en-CA">en-CA</option>
                                    <option value="en-GB">en-GB</option>
                                    <option value="en-US">en-US</option>
                                    <option value="es">es</option>
                                    <option value="es-ES">es-ES</option>
                                    <option value="fr">fr</option>
                                    <option value="fr-BE">fr-BE</option>
                                    <option value="fr-CA">fr-CA</option>
                                    <option value="fr-CH">fr-CH</option>
                                    <option value="fr-FR">fr-FR</option>
                                    <option value="fi">fi</option>
                                    <option value="fi-FI">fi-FI</option>
                                    <option value="it">it</option>
                                    <option value="it-IT">it-IT</option>
                                    <option value="nl">nl</option>
                                    <option value="nl-BE">nl-BE</option>
                                    <option value="nl-NL">nl-NL</option>
                                    <option value="pt">pt</option>
                                    <option value="pt-PT">pt-PT</option>
                                </select>
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field--checkbox">
                            <div className="wz-lyriapay__dev-integration__field__label--checkbox">
                                Hide &apos;Pay&apos; button
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input--checkbox">
                                <input
                                    type="checkbox"
                                    checked={hidePayButton}
                                    onChange={() => setHidePayButton((v) => !v)}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field--checkbox">
                            <div className="wz-lyriapay__dev-integration__field__label--checkbox">
                                Expand option if only one option
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input--checkbox">
                                <input
                                    type="checkbox"
                                    checked={expandOptionIfOnlyOne}
                                    onChange={() => setExpandOptionIfOnlyOne((v) => !v)}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field--checkbox">
                            <div className="wz-lyriapay__dev-integration__field__label--checkbox">
                                Force all payment methods in a expandable button
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input--checkbox">
                                <input
                                    type="checkbox"
                                    checked={forceDisplayPaymentMethodsInExpandableButton}
                                    onChange={() => setForceDisplayPaymentMethodsInExpandableButton((v) => !v)}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field--checkbox">
                            <div className="wz-lyriapay__dev-integration__field__label--checkbox">
                                Display express checkout title (&apos;Express checkout&apos;)
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input--checkbox">
                                <input
                                    type="checkbox"
                                    checked={shouldDisplayExpressCheckoutTitle}
                                    onChange={() => setShouldDisplayExpressCheckoutTitle((v) => !v)}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field--checkbox">
                            <div className="wz-lyriapay__dev-integration__field__label--checkbox">
                                Display payment methods title (&apos;Payment methods&apos;)
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input--checkbox">
                                <input
                                    type="checkbox"
                                    checked={shouldDisplayPaymentMethodsTitle}
                                    onChange={() => setShouldDisplayPaymentMethodsTitle((v) => !v)}
                                />
                            </div>
                        </div>
                        <div className="wz-lyriapay__dev-integration__field--checkbox">
                            <div className="wz-lyriapay__dev-integration__field__label--checkbox">
                                Dark mode
                            </div>
                            <div className="wz-lyriapay__dev-integration__field__input--checkbox">
                                <input
                                    type="checkbox"
                                    checked={darkMode}
                                    onChange={() => setDarkMode((v) => !v)}
                                />
                            </div>
                        </div>
                    </div>
                </DevExpandableBlock>
            </div>
            {token && (
                <>
                    <div className="wz-lyriapay__dev-integration__result">
                        <DevExpandableBlock
                            header="Lyria Pay Interaction"
                            expanded
                        >
                            <div className="wz-lyriapay__dev-integration__field-group">
                                <div className="wz-lyriapay__dev-integration__field">
                                    <div className="wz-lyriapay__dev-integration__field__label">
                                        External host &apos;Pay&apos; button
                                    </div>
                                    <div className="wz-lyriapay__dev-integration__field__input">
                                        <button
                                            disabled={hostPayButtonDisabled}
                                            onClick={() => {
                                                if (integrationRef.current) {
                                                    const processing = integrationRef.current.pay();
                                                    setHostPayButtonDisabled(processing);
                                                }
                                            }}
                                        >
                                            Host pay button
                                        </button>
                                    </div>
                                </div>
                                {lastPaymentResult && (
                                    <div className="wz-lyriapay__dev-integration__field">
                                        <div className="wz-lyriapay__dev-integration__field__label">
                                            Result from Lyria (backend)
                                        </div>
                                        <div className="wz-lyriapay__dev-integration__field__input">
                                            <textarea
                                                rows="4"
                                                disabled
                                                value={lastPaymentResult || ''}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </DevExpandableBlock>
                    </div>
                    <div className="wz-lyriapay__dev-integration__integration">
                        <LyriaPay
                            ref={integrationRef}
                            lyriaUrl={lyriaUrl}
                            token={token}
                            labelPayButton={labelPayButton}
                            labelPayButtonApplePayAndGooglePay={labelPayButtonApplePayGooglePay}
                            hidePayButton={hidePayButton}
                            forceDisplayPaymentMethodsInExpandableButton={forceDisplayPaymentMethodsInExpandableButton}
                            shouldDisplayExpressCheckoutTitle={shouldDisplayExpressCheckoutTitle}
                            shouldDisplayPaymentMethodsTitle={shouldDisplayPaymentMethodsTitle}
                            onPaymentResult={(result) => {
                                setLastPaymentResult(JSON.stringify(result));
                                if (result.success && 0 > 1) {
                                    window.alert('Payment success !');
                                } else {
                                    setHostPayButtonDisabled(false);
                                }
                            }}
                            lang={lang}
                            themeMode={darkMode ? 'dark' : 'light'}
                            primaryColor={primaryColor}
                            loader={customLoader || undefined}
                            expandOptionIfOnlyOne={expandOptionIfOnlyOne}
                            paymentCardInputWarningMessage={paymentCardInputWarningMessage}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default DevIntegration;
