import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'lib/components/Icon';
import './InfoBlock.css';

/**
 * This component is used to display a clean error message that can be readable by users.
 * It's mainly used for global errors not related to a particular form field and to display at the top op the window.
 */
const InfoBlock = ({ title, message }) => (
    <div id="wz-lyriapay__info-block" className="wz-lyriapay__info-block">
        <div className="wz-lyriapay__info-block__title">
            <div className="wz-lyriapay__info-block__title--icon">
                <Icon id="info" style={{ color: 'var(--primary, #0032fa)' }} />
            </div>
            <div className="wz-lyriapay__info-block__title--content">
                {title}
            </div>
        </div>
        <div className="wz-lyriapay__info-block__message">
            {message}
        </div>
    </div>
);

InfoBlock.propTypes = {
    /**
     * The title of the information block
     * */
    title: PropTypes.string,
    /**
     * The message to display in the information block
     * */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

InfoBlock.defaultProps = {
    title: null,
    message: null,
};

InfoBlock.displayName = 'ErrorBlock';

export default InfoBlock;
