import React from 'react';

import ReactDOM from 'react-dom';
import DevIntegration from 'dev/components/DevIntegration';
import PaymentWidget from 'payment/PaymentWidget';

/**
 * This is the entry point.
 * yarn start runs a standalone Payment Page.
 * yarn start:devIntegration runs a Dev Integration for local development.
 * Otherwise, LyriaPay is used as a library and so not as a running application.
 */

const App = process.env.REACT_APP_PAYMENT_PAGE === 'true' ? PaymentWidget : DevIntegration;
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
