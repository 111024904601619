import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormField from 'lib/components/FormField/FormField';
import Button from 'lib/components/Button';
import './Clabe.css';

const CURP_REGEX = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

/**
 * This component is one of the multiple available payment method in this project.
 * It represents a CLABE (mexican bank system) form, only used to collect refund request data for now.
 */
const Clabe = React.forwardRef(
    (
        {
            pspId,
            token,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            firstName: '',
            lastName: '',
            clabeNumber: '',
            curpNumber: '',
            phoneNumber: '',
            bankCardType: '',
        });
        const [formFieldBlurState, setFormFieldBlurState] = React.useState({
            firstName: false,
            lastName: false,
            clabeNumber: false,
            curpNumber: false,
            phoneNumber: false,
            bankCardType: false,
        });
        const [errors, setErrors] = React.useState({});

        const handleSuccess = React.useCallback(
            () => {
                onPaymentResult({ success: true });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `clabe_${key}_error_${error.code}`,
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({
                    success: false,
                    data: response,
                });
            },
            [onPaymentResult, t],
        );

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const data = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    clabe_number: formData.clabeNumber,
                    curp_number: formData.curpNumber,
                    phone_number: formData.phoneNumber,
                    bank_card_type: formData.bankCardType,
                };

                axios({
                    method: 'post',
                    url: lyriaUrl + '/clabe/save',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        data: data,
                        request_token: token,
                        psp: pspId,
                    },
                })
                    .then(({ _data }) => {
                        handleSuccess();
                    })
                    .catch((error) => {
                        if (error.response?.status === 400) {
                            handleError(error.response.data);
                        } else {
                            handleError({ code: 'internal' });
                            console.error(error.response);
                        }
                    });
            },
            [formData, handleError, handleSuccess, lyriaUrl, pspId, token],
        );

        const formatAndValidateClabe = React.useCallback(
            (value) => {
                let newValue = value.toUpperCase().replace(/\s+/g, '');
                let matches = newValue.match(/[0-9]+/g);
                newValue = (matches && matches.join('')) || '';

                setErrors((prevState) => ({
                    ...prevState,
                    clabe_number: newValue.length !== 18 ? [t('clabe_clabe_number_error_min_length')] : null,
                }));

                return newValue;
            },
            [t],
        );

        const formatAndValidateCurp = React.useCallback(
            (value) => {
                let newValue = value.toUpperCase().replace(/\s+/g, '');
                let matches = newValue.substr(0, 18).match(/[A-Z0-9]/g);
                newValue = matches?.join('') || '';

                let curpError = null;
                if (newValue.length !== 18) {
                    curpError = [t('clabe_curp_number_error_min_length')];
                } else if (!newValue.match(CURP_REGEX)) {
                    curpError = [t('clabe_curp_number_error_invalid')];
                }

                setErrors((prevState) => ({
                    ...prevState,
                    curp_number: curpError,
                }));

                return newValue;
            },
            [t],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));
            },
            [],
        );

        const onBlurFormField = React.useCallback(
            (fieldName) => (_event) => {
                setFormFieldBlurState((prevState) => ({
                    ...prevState,
                    [fieldName]: true,
                }));
            },
            [],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: Object.values(formData).every((value) => !!value),
            }),
            [formData],
        );

        return (
            <div id="wz-lyriapay__clabe" className="wz-lyriapay__common__psp">
                <form noValidate onSubmit={submitForm}>
                    <div className="wz-lyriapay__common__psp__form">
                        <div className="wz-lyriapay__common__psp__form__field-group">
                            <FormField
                                name="first_name"
                                value={formData.firstName}
                                onChange={onChangeFormField('firstName')}
                                label={t('clabe_first_name')}
                                errors={errors.first_name}
                            />
                            <FormField
                                name="last_name"
                                value={formData.lastName}
                                onChange={onChangeFormField('lastName')}
                                label={t('clabe_last_name')}
                                errors={errors.last_name}
                            />
                        </div>
                        <FormField
                            name="clabe_number"
                            value={formData.clabeNumber}
                            onChange={onChangeFormField('clabeNumber')}
                            onBlur={onBlurFormField('clabeNumber')}
                            label={t('clabe_clabe_number')}
                            formatAndValidate={formatAndValidateClabe}
                            errors={
                                (errors.clabe_number?.length || 0) > 1
                                    ? formFieldBlurState.clabeNumber ? [errors.clabe_number[0]] : null
                                    : formFieldBlurState.clabeNumber ? errors.clabe_number : null
                            }
                        />
                        <FormField
                            name="curp_number"
                            value={formData.curpNumber}
                            onChange={onChangeFormField('curpNumber')}
                            onBlur={onBlurFormField('curpNumber')}
                            label={t('clabe_curp_number')}
                            formatAndValidate={formatAndValidateCurp}
                            errors={
                                (errors.curp_number?.length || 0) > 1
                                    ? formFieldBlurState.curpNumber ? [errors.curp_number[0]] : null
                                    : formFieldBlurState.curpNumber ? errors.curp_number : null
                            }
                        />
                        <FormField
                            name="phone_number"
                            value={formData.phoneNumber}
                            onChange={onChangeFormField('phoneNumber')}
                            label={t('clabe_phone_number')}
                            errors={errors.phone_number}
                        />
                        <FormField
                            name="bank_card_type"
                            value={formData.bankCardType}
                            onChange={onChangeFormField('bankCardType')}
                            label={t('clabe_bank_card_type')}
                            errors={errors.bank_card_type}
                            type="select"
                            required
                            options={[
                                { value: '', label: '' },
                                { value: 'credit', label: t('clabe_bank_card_type_type_credit') },
                                { value: 'debit', label: t('clabe_bank_card_type_type_debit') },
                            ]}
                        />
                    </div>
                    {!hidePayButton && (
                        <div className="wz-lyriapay__common__psp__submit">
                            <Button
                                disabled={Object.values(formData).some((value) => !value) || formData.clabeNumber.length !== 18 || formData.curpNumber.length !== 18}
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

Clabe.propTypes = {
    /**
     * The id of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string.isRequired,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

Clabe.defaultProps = {
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

Clabe.displayName = 'Clabe';

export default Clabe;
