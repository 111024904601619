import React from 'react';

import LyriaPay from 'lib';

const PaymentPage = () => {
    const integrationRef = React.useRef();
    // eslint-disable-next-line no-unused-vars
    const [lyriaUrl, setLyriaUrl] = React.useState(
        window.location.protocol === 'https:'
            ? `${window.location.origin}`
            : `http://${window.location.hostname}:8003`,
    );
    const [token, setToken] = React.useState();
    // eslint-disable-next-line no-unused-vars
    const [labelPayButton, setLabelPayButton] = React.useState('');
    // eslint-disable-next-line no-unused-vars
    const [labelPayButtonApplePayGooglePay, setLabelPayButtonApplePayGooglePay] = React.useState('buy');
    // eslint-disable-next-line no-unused-vars
    const [hidePayButton, setHidePayButton] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [forceDisplayPaymentMethodsInExpandableButton, setForceDisplayPaymentMethodsInExpandableButton] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [shouldDisplayExpressCheckoutTitle, setShouldDisplayExpressCheckoutTitle] = React.useState(true);
    // eslint-disable-next-line no-unused-vars
    const [shouldDisplayPaymentMethodsTitle, setShouldDisplayPaymentMethodsTitle] = React.useState(true);
    // eslint-disable-next-line no-unused-vars
    const [hostPayButtonDisabled, setHostPayButtonDisabled] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [lastPaymentResult, setLastPaymentResult] = React.useState(null);
    // eslint-disable-next-line no-unused-vars
    const [lang, setLang] = React.useState('fr');
    // eslint-disable-next-line no-unused-vars
    const [darkMode, setDarkMode] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [primaryColor, setPrimaryColor] = React.useState('#0032fa');
    // eslint-disable-next-line no-unused-vars
    const [customLoader, setCustomLoader] = React.useState('');
    // eslint-disable-next-line no-unused-vars
    const [expandOptionIfOnlyOne, setExpandOptionIfOnlyOne] = React.useState(true);
    // eslint-disable-next-line no-unused-vars
    const [paymentCardInputWarningMessage, setPaymentCardInputWarningMessage] = React.useState('');
    // eslint-disable-next-line no-unused-vars
    const [amount, setAmount] = React.useState(5000);
    // eslint-disable-next-line no-unused-vars
    const [service, setService] = React.useState('gill');
    // eslint-disable-next-line no-unused-vars
    const [currency, setCurrency] = React.useState('EUR');
    // eslint-disable-next-line no-unused-vars
    const [tokenType, setTokenType] = React.useState('payment');

    React.useEffect(() => {
        setToken(localStorage.getItem('token'));
    }, []);


    return (
        <div className="wz-lyriapay__dev-integration__integration">
            {token &&
            <LyriaPay
                ref={integrationRef}
                lyriaUrl={lyriaUrl}
                token={token}
                labelPayButton={labelPayButton}
                labelPayButtonApplePayAndGooglePay={labelPayButtonApplePayGooglePay}
                hidePayButton={hidePayButton}
                forceDisplayPaymentMethodsInExpandableButton={forceDisplayPaymentMethodsInExpandableButton}
                shouldDisplayExpressCheckoutTitle={shouldDisplayExpressCheckoutTitle}
                shouldDisplayPaymentMethodsTitle={shouldDisplayPaymentMethodsTitle}
                onPaymentResult={(result) => {
                    setLastPaymentResult(JSON.stringify(result));
                    if (result.success && 0 > 1) {
                        window.alert('Payment success !');
                    } else {
                        setHostPayButtonDisabled(false);
                    }
                }}
                lang={lang}
                themeMode={darkMode ? 'dark' : 'light'}
                primaryColor={primaryColor}
                loader={customLoader || undefined}
                expandOptionIfOnlyOne={expandOptionIfOnlyOne}
                paymentCardInputWarningMessage={paymentCardInputWarningMessage}
            />
            }
        </div>
    );
};

export default PaymentPage;
