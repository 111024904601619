import React from 'react';

import DevIntegration from 'dev/components/DevIntegration';
import PaymentPage from 'payment/PaymentPage';

const PaymentWidget = () => {
    return (
        <>
            {window.location.pathname === '/test' &&
                <DevIntegration />
            }
            {window.location.pathname === '/' &&
                <PaymentPage />
            }
        </>
    );
};

export default PaymentWidget;
