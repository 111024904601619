import i18next from 'i18next';
import requireContext from 'require-context.macro';
import { initReactI18next } from 'react-i18next';

const i18n = i18next.createInstance();

function merge(translations, firstFallback) {
    if (Object.keys(translations).length) {
        return { ...firstFallback, ...translations };
    } else {
        // no need to merge if translations is empty
        return firstFallback;
    }
}

const importTranlations = process.env.NODE_ENV === 'test' ? requireContext('../locales/', true, /\.json$/) : require.context('../locales/', true, /\.json$/);  // a bit of webpack magic to import all files in a directory https://webpack.js.org/guides/dependency-management/#requirecontext

let translations = {};

// first, fill in the default locales
// ex the file named default-fr-fr.json will be used for fr, fr-fr, and as a fallback for other fr-* locales, like fr-ca
for (let filename of importTranlations.keys()) {
    const locale = filename.slice(2, -5).toLowerCase(); // get './fr-FR.json', return 'fr-fr'
    if (locale.indexOf('default-') === 0) {
        const realLocale = locale.slice(8);
        const content = importTranlations(filename);
        translations[realLocale] = { translation: content };
        const localeParts = realLocale.split('-');
        if (localeParts.length > 1) {
            translations[localeParts[0]] = { translation: content }; // if realLocale is fr-fr, store translations for fr
        }
    }
}

// then the non default locales, and use the default of their language as a fallback
for (let filename of importTranlations.keys()) {
    const locale = filename.slice(2, -5).toLowerCase(); // get './fr-FR.json', return 'fr-fr'
    if (locale.indexOf('default-') !== 0) {
        const localeParts = locale.split('-');
        const content = importTranlations(filename);
        if (localeParts.length > 1) {
            translations[locale] = { translation: merge(content, translations[localeParts[0]]?.translation) };
        } else {
            translations[locale] = { translation: content };
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        supportedLngs: Object.keys(translations),
        lowerCaseLng: true,
        resources: translations,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })
    .then()
    .catch(() => {
        console.error('Fail to load translations (i18next)');
    });

export default i18n;
