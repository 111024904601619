import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import FormField from 'lib/components/FormField';
import './PaypalForm.css';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Form for PayPal refund considered as a PSP (but it's not a real PayPal integration).
 * The goal is to be able to refund user on its PayPal account.
 */
const PaypalForm = React.forwardRef(
    (
        {
            pspId,
            token,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        });
        const [errors, setErrors] = React.useState({});

        const handleSuccess = React.useCallback(
            () => {
                onPaymentResult({ success: true });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({ success: false, data: response });
            },
            [onPaymentResult, t],
        );

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const data = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    email: formData.email,
                    phone_number: formData.phoneNumber,
                };

                axios({
                    method: 'post',
                    url: lyriaUrl + '/paypal-form/save',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        data: data,
                        request_token: token,
                        psp: pspId,
                    },
                })
                    .then(({ _data }) => {
                        handleSuccess();
                    })
                    .catch((error) => {
                        if (error.response?.status === 400) {
                            handleError(error.response.data);
                        } else {
                            handleError({ code: 'internal' });
                            console.error(error.response);
                        }
                    });
            },
            [formData, handleError, handleSuccess, lyriaUrl, pspId, token],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));
            },
            [],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: Object.values(formData).every((value) => !!value),
            }),
            [formData],
        );

        return (
            <div id="wz-lyriapay__paypal-form" className="wz-lyriapay__common__psp">
                <form noValidate onSubmit={submitForm}>
                    <div className="wz-lyriapay__common__psp__form">
                        <div className="wz-lyriapay__common__psp__form__field-group">
                            <FormField
                                name="first_name"
                                value={formData.firstName}
                                onChange={onChangeFormField('firstName')}
                                label={t('paypal_first_name')}
                                errors={errors.first_name}
                            />
                            <FormField
                                name="last_name"
                                value={formData.lastName}
                                onChange={onChangeFormField('lastName')}
                                label={t('paypal_last_name')}
                                errors={errors.last_name}
                            />
                        </div>
                        <FormField
                            name="email"
                            value={formData.email}
                            onChange={onChangeFormField('email')}
                            label={t('paypal_account_email')}
                            errors={errors.email}
                        />
                        <FormField
                            name="phone_number"
                            value={formData.phoneNumber}
                            onChange={onChangeFormField('phoneNumber')}
                            label={t('paypal_phone_number')}
                            errors={errors.phone_number}
                        />
                    </div>
                    {!hidePayButton && (
                        <div className="wz-lyriapay__common__psp__submit">
                            <Button
                                disabled={Object.values(formData).some((value) => !value)}
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

PaypalForm.propTypes = {
    /**
     * The id of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string.isRequired,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

PaypalForm.defaultProps = {
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

PaypalForm.displayName = 'PaypalForm';

export default PaypalForm;
