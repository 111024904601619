import React from 'react';

import PropTypes from 'prop-types';

import './DevExpandableBlock.css';

/**
 * This component is a simple block with a header and a content that can be expandable.
 */
const DevExpandableBlock = ({ expanded, header, children }) => {
    const [open, setOpen] = React.useState(expanded);

    React.useEffect(
        () => {
            setOpen(expanded);
        },
        [expanded],
    );

    if (!header && !children) {
        return null;
    }

    return (
        <div className="wz-lyriapay__dev-expandable-block">
            <div
                className="wz-lyriapay__dev-expandable-block__header"
                onClick={() => {
                    setOpen((prevState) => !prevState);
                }}
            >
                <div className="wz-lyriapay__dev-expandable-block__header__left">
                    {header}
                </div>
                <div className="wz-lyriapay__dev-expandable-block__header__right">
                    {open
                        ? <>&darr;</>
                        : <>&rarr;</>
                    }
                </div>
            </div>
            {children && open && (
                <div className="wz-lyriapay__dev-expandable-block__content">
                    {children}
                </div>
            )}
        </div>
    );
};

DevExpandableBlock.propTypes = {
    /**
     * Whether the block should be expanded.
     */
    expanded: PropTypes.bool,
    /**
     * The title or the header of the block.
     */
    header: PropTypes.any,
    /**
     * The content of the block when it is expanded.
     */
    children: PropTypes.any,
};

DevExpandableBlock.defaultProps = {
    expanded: false,
    header: null,
    children: null,
};

export default DevExpandableBlock;
