import React from 'react';

import PropTypes from 'prop-types';

import './Button.css';

/**
 * This component is the common button used as the submit button for any form in this project
 * Mainly, this button is used to trigger a payment.
 */
const Button = React.forwardRef(
    (
        {
            label,
            type,
            disabled,
            hideButton,
            onClick,
            ...props
        },
        ref,
    ) => {
        return (
            <div id="wz-lyriapay__button">
                <button
                    className="wz-lyriapay__button__button"
                    type={type}
                    ref={ref}
                    onClick={onClick}
                    /* Note: we hide the button instead of removing it from the DOM because the browser will trigger
                    the buttonʼs onClick if we press Enter in any of the form input it work if the button is hidden,
                    it doesnʼt work if there is no button */
                    style={hideButton ? { display: 'none' } : {}}
                    disabled={disabled}
                    { ...props }
                >
                    {label}
                </button>
            </div>
        );
    },
);

Button.propTypes = {
    /**
     * The label to display on the button.
     */
    label: PropTypes.string,
    /**
     * The button type
     */
    type: PropTypes.string,
    /**
     * Whether the button should be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Whether the button must be displayed or not. Anyway it will be included in the DOM.
     */
    hideButton: PropTypes.bool,
    /**
     * The callback function to trigger when we click on the button.
     */
    onClick: PropTypes.func,
    /**
     * All the other props that can be passed to the button html element and not explicitly given in the props
     */
    props: PropTypes.object,
};

Button.defaultProps = {
    label: null,
    type: null,
    disabled: false,
    hideButton: false,
    onClick: null,
};

Button.displayName = 'Button';

export default Button;
