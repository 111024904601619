import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'lib/components/Icon';
import './Tooltip.css';

/**
 * A basic tooltip to use to let the user be able to get more information about something.
 * It displays an icon with an interrogation point that let the information (children) display on click.
 */
const Tooltip = ({ children, alt }) => {
    const [isShown, setIsShown] = React.useState(false);

    if (!children) {
        return null;
    }

    return (
        <div className="wz-lyriapay__tooltip">
            <div className='wz-lyriapay__tooltip-icon' onClick={() => setIsShown(!isShown)} alt={alt}>
                <Icon id='tooltip' />
            </div>
            <div
                className="wz-lyriapay__tooltip__content"
                aria-hidden={isShown}
                role="tooltip"
                style={{ visibility: isShown ? 'visible' : 'hidden' }}
                onClick={() => setIsShown(!isShown)}
            >
                {children}
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    /**
     * The component to include in this Tooltip component.
     * This what should be displayed when we hover or click on the tooltip icon.
     */
    children: PropTypes.node,
    /**
     * The html alt property to pass to the tooltip icon.
     */
    alt: PropTypes.string,
};

Tooltip.defaultProps = {
    children: null,
    alt: null,
};

export default Tooltip;
