import React from 'react';

import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import Icon from 'lib/components/Icon';
import './PaymentMethodOption.css';

/**
 * This component is a payment method choice / button expandable to display in the main component.
 * The user must select one payment method option to continue the flow (for payment or credit)
 */
const PaymentMethodOption = ({
    isSelected,
    isDisabled,
    isComponentOnly,
    shouldHideTheExpandableButtonWhenOpen,
    operationType,
    name,
    icons,
    paymentMethods = [], // eslint-disable-line no-unused-vars
    feesAmount,
    minimumAmount,
    onClick,
    paymentMethodComponent,
    themeMode,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(isSelected);

    const displayHeader = React.useMemo(
        () => !(shouldHideTheExpandableButtonWhenOpen && isExpanded),
        [shouldHideTheExpandableButtonWhenOpen, isExpanded],
    );

    const iconsComponents = React.useMemo(
        () => icons.map((icon) => (<Icon key={icon} id={icon} themeMode={themeMode} />)),
        [icons, themeMode],
    );

    const subTitlesComponents = React.useMemo(
        () => {
            const subtitles = [];
            if (operationType === 'credit') {
                if (isDisabled) {
                    if (minimumAmount) {
                        subtitles.push(
                            <div className="wz-lyriapay__payment-method-option__header__main__subtitles__item--credit-minimum-amount">
                                <Trans i18nKey="minAmountForCredit" />{minimumAmount}
                            </div>,
                        );
                    }
                } else {
                    if (feesAmount) {
                        subtitles.push(
                            <div className="wz-lyriapay__payment-method-option__header__main__subtitles__item--credit-fee-amount">
                                <Trans i18nKey="refundFee" />{feesAmount}
                            </div>,
                        );
                    }
                }
            }
            return subtitles;
        },
        [feesAmount, minimumAmount, operationType, isDisabled],
    );

    React.useEffect(
        () => {
            if (isSelected !== null && isSelected !== undefined) {
                setIsExpanded(isSelected);
            }
        },
        [isSelected],
    );

    const onClickCallback = React.useCallback(
        () => {
            if (!isDisabled) {
                setIsExpanded((previous) => {
                    if (onClick) {
                        onClick(!previous);
                    }
                    return !previous;
                });
            }
        },
        [isDisabled, onClick],
    );

    let classNameHeader = 'wz-lyriapay__payment-method-option__header';
    let classNameContent =  'wz-lyriapay__payment-method-option__content';
    if (displayHeader && isExpanded && paymentMethodComponent) {
        classNameHeader = 'wz-lyriapay__payment-method-option__header--header-and-content';
    } else if (isDisabled) {
        classNameHeader = 'wz-lyriapay__payment-method-option__header--disabled';
    }
    if (displayHeader) {
        classNameContent = 'wz-lyriapay__payment-method-option__content-and-header';
    }

    return (
        <div id="wz-lyriapay__payment-method-option">
            {displayHeader && !isComponentOnly && (
                <div className={classNameHeader} onClick={onClickCallback}>
                    <div className="wz-lyriapay__payment-method-option__header__main">
                        <div className="wz-lyriapay__payment-method-option__header__main__title-icons">
                            {name && (
                                <div className="wz-lyriapay__payment-method-option__header__main__title-icons__title">
                                    {name}
                                </div>
                            )}
                            {!!iconsComponents?.length && (
                                <div className="wz-lyriapay__payment-method-option__header__main__title-icons__icons">
                                    {iconsComponents.map((iconComponent, index) => (
                                        <div
                                            key={`icon_${index}`}
                                            className="wz-lyriapay__payment-method-option__header__main__title-icons__icons__wrapper"
                                        >
                                            {iconComponent}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {!!subTitlesComponents?.length && (
                            <div className="wz-lyriapay__payment-method-option__header__main__subtitles">
                                {subTitlesComponents.map((subTitlesComponent, index) => (
                                    <div
                                        key={`subtitle_${index}`}
                                        className="wz-lyriapay__payment-method-option__header__main__subtitles__item"
                                    >
                                        {subTitlesComponent}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="wz-lyriapay__payment-method-option__header__arrow">
                        <div className="wz-lyriapay__payment-method-option__header__arrow__icons-wrapper">
                            {
                                isExpanded && !!paymentMethodComponent
                                    ? <Icon id="arrowDown" themeMode={themeMode} />
                                    : <Icon id="arrowRight" themeMode={themeMode} />
                            }
                        </div>
                    </div>
                </div>
            )}
            {isExpanded && !!paymentMethodComponent && !isComponentOnly && (
                <div className={classNameContent}>
                    {paymentMethodComponent}
                </div>
            )}
            {isComponentOnly && (
                <>{paymentMethodComponent}</>
            )}
        </div>
    );
};

PaymentMethodOption.propTypes = {
    /**
     * Whether the button is selected and should be expanded.
     */
    isSelected: PropTypes.bool,
    /**
     * Whether the button should be disabled and so not expandable or not.
     */
    isDisabled: PropTypes.bool,
    /**
     * Whether the component must be displayed alone with no wrapper.
     */
    isComponentOnly: PropTypes.bool,
    /**
     * Whether the expandable button 'header' should be hidden when the payment method is expanded (selected).
     */
    shouldHideTheExpandableButtonWhenOpen: PropTypes.bool,
    /**
     * In which context we are, the type of operation that will be processed.
     */
    operationType: PropTypes.oneOf(['credit', 'payment']),
    /**
     * The title of the payment method to display.
     */
    name: PropTypes.string,
    /**
     * The list of icons to display close to the name of the payment method.
     */
    icons: PropTypes.arrayOf(Icon.propTypes.id),
    /**
     * The list of payment methods that belongs to the given payment method. That's a kind of sub payment method.
     * For example, for 'credit card' payment method we could have Visa, Mastercard and so on.
     */
    paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            payment_method: PropTypes.string.isRequired,
            is_card: PropTypes.bool,
        }),
    ),
    /**
     * The fee amount that will be applied if this payment method is chosen. It is already formatted with symbol.
     */
    feesAmount: PropTypes.string,
    /**
     * The minimum amount required to be allowed to use this payment method. It is already formatted with symbol.
     * This is mainly used for 'credit' operation to avoid too low amounts that costs too much money for us.
     */
    minimumAmount: PropTypes.string,
    /**
     * The maximum amount required to be allowed to use this payment method. It is already formatted with symbol.
     * This is to prevent too high amounts we don't allow.
     */
    maximumAmount: PropTypes.string,
    /**
     * The callback function to trigger when we expand and collapse on the payment button (header).
     * The callback returns a boolean to say whether the component is expanded or not.
     */
    onClick: PropTypes.func,
    /**
     * The main business component to display when the button is expanded.
     * This is the component related to the payment method the user must fill to continue the process.
     */
    paymentMethodComponent: PropTypes.element,
    /**
     * The theme mode to display the appropriate version of the icon (if exist).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
};

PaymentMethodOption.defaultProps = {
    isSelected: false,
    isDisabled: false,
    isComponentOnly: false,
    shouldHideTheExpandableButtonWhenOpen: false,
    operationType: null,
    name: null,
    icons: [],
    paymentMethods: [],
    feesAmount: null,
    minimumAmount: null,
    maximumAmount: null,
    onClick: null,
    paymentMethodComponent: null,
    themeMode: 'light',
};

export default PaymentMethodOption;
