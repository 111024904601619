import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormField from 'lib/components/FormField';
import Icon from 'lib/components/Icon';
import {
    keepOnlyDigits,
    formatCreditCardNumber,
    findCardType,
    luhnValidate,
} from 'lib/utils/cardUtils';
import './CardNumberInput.css';

const CardNumberInput = ({
    defaultCardNumber,
    configCardTypes,
    maskedNumber,
    onChange,
    disabled,
    forceDirty,
}) => {
    const { t } = useTranslation();

    const [isValid, setValid] = React.useState(false);
    const [textValue, setTextValue] = React.useState(defaultCardNumber);
    const [isDirty, setDirty] = React.useState(false);
    const [cardTypeConfig, setCardTypeConfig] = React.useState(null);
    const [isFocused, setFocused] = React.useState(false);

    React.useEffect(() => {
        if (maskedNumber) {
            const ctc = findCardType(maskedNumber, configCardTypes);
            setCardTypeConfig(ctc);
        } else {
            setCardTypeConfig(null);
        }
    }, [maskedNumber, configCardTypes]);

    const cardIcon = React.useMemo(
        () => (
            <div className="wz-lyriapay__card-input__card-number-input__icon">
                <Icon id={configCardTypes.find((configCardType) => cardTypeConfig?.type === configCardType.type)?.type} />
            </div>
        ),
        [cardTypeConfig, configCardTypes],
    );

    const formatAndValidateCardNumber = React.useCallback(
        (value) => {
            const cardNumberTextValue = value;
            const cardNumberCleanTextValue = keepOnlyDigits(cardNumberTextValue);
            const ctc = findCardType(cardNumberCleanTextValue, configCardTypes);
            let valid = !!ctc;
            if (ctc && ctc.validation === 'luhn') {
                valid = luhnValidate(cardNumberCleanTextValue);
            }
            setValid(valid);
            setCardTypeConfig(ctc);
            return formatCreditCardNumber(cardNumberCleanTextValue);
        },
        [configCardTypes],
    );

    const onChangeCardNumberInput = React.useCallback(
        (event, newValue) => {
            setDirty(true);
            setTextValue(newValue);
        },
        [],
    );

    React.useEffect(
        () => {
            if (onChange) {
                onChange({
                    value: textValue?.replace(/\s+/g, ''),
                    isValid: isValid,
                    cardTypeConfig: cardTypeConfig,
                });
            }
        },
        [onChange, isValid, textValue, cardTypeConfig],
    );

    if (maskedNumber) {
        return (
            <div id="wz-lyriapay__card-input__card-number-input">
                <FormField
                    id='cardnumber'
                    name='cardnumber'
                    label={t('cardNumberLabel')}
                    value={maskedNumber}
                    disabled={disabled}
                />
            </div>
        );
    }

    return (
        <div id="wz-lyriapay__card-input__card-number-input">
            <FormField
                id='cardnumber'
                name='cardnumber'
                label={t('cardNumberLabel')}
                required
                shouldDisplayAsteriskIfRequired={false}
                autoComplete="cc-number"
                pattern="\d*"
                value={textValue}
                disabled={disabled}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                onChange={onChangeCardNumberInput}
                formatAndValidate={formatAndValidateCardNumber}
                errors={
                    (isDirty || forceDirty) && !isValid && !isFocused
                        ? textValue === ''
                            ? [t('cardNumberEmpty')]
                            : cardTypeConfig
                                ? [t('cardNumberInvalid')]
                                : [t('cardNumberUnhandled')]
                        : null
                }
                headerEndComponent={cardIcon}
                inputProps={{
                    inputMode: 'numeric',
                }}
            />
        </div>
    );
};

CardNumberInput.propTypes = {
    /**
     * The default card number in the input
     */
    defaultCardNumber: PropTypes.string,
    /**
     * An array with the card types allowed and the config related to each type.
     */
    configCardTypes: PropTypes.arrayOf(PropTypes.object),
    /**
     * The masked number of the card to be displayed in the input.
     * That's mainly use to display a saved card without the possibility to modify it.
     */
    maskedNumber: PropTypes.string,
    /**
     * The callback function to trigger when the input is changed.
     * The function takes the arguments: value, isValid, cardType.
     */
    onChange: PropTypes.func,
    /**
     * Whether the input must be disabled (just in read only) or not.
     */
    disabled: PropTypes.bool,
    /**
     * Whether we want to set the input to be dirty (that is to say as it has already been touched by the user).
     * That's mainly for error display purpose.
     */
    forceDirty: PropTypes.bool,
};

CardNumberInput.defaultProps = {
    defaultCardNumber: '',
    configCardTypes: [],
    maskedNumber: null,
    onChange: null,
    disabled: false,
    forceDirty: false,
};

export default CardNumberInput;
