import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import {Trans, useTranslation} from 'react-i18next';
import InfoBlock from 'lib/components/InfoBlock';
import FormField from 'lib/components/FormField/FormField';
import Button from 'lib/components/Button';
import './iban.css';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents an IBAN form, only used to collect refund request data for now.
 */
const Iban = React.forwardRef(
    (
        {
            config,
            currency,
            pspId,
            token,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            iban: '',
            bic: '',
            holder: '',
        });
        const [formFieldBlurState, setFormFieldBlurState] = React.useState({
            iban: false,
            bic: false,
            holder: false,
        });
        const [errors, setErrors] = React.useState({});
        const [isBicRequired, setIsBicRequired] = React.useState(config?.collect_bic);

        const handleSuccess = React.useCallback(
            () => {
                onPaymentResult({ success: true });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `iban_${key}_error_${error.code}`,
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({
                    success: false,
                    data: response,
                });
            },
            [onPaymentResult, t],
        );

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const data = {
                    iban: formData.iban.replace(/\s+/g, ''),
                    holder: formData.holder,
                };
                if (isBicRequired) {
                    data['bic'] = formData.bic;
                }

                axios({
                    method: 'post',
                    url: lyriaUrl + '/iban/save',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        data: data,
                        request_token: token,
                        psp: pspId,
                    },
                })
                    .then(() => {
                        handleSuccess();
                    })
                    .catch((error) => {
                        if (error.response?.status === 400) {
                            handleError(error.response.data);
                        } else {
                            handleError({ code: 'internal' });
                            console.error(error.response);
                        }
                    });
            },
            [isBicRequired, formData, handleError, handleSuccess, lyriaUrl, pspId, token],
        );

        const formatAndValidateIban = React.useCallback(
            (value) => {
                let newValue = value.toUpperCase().replace(/\s+/g, '');
                let matches = newValue.match(/[0-9A-Z]+/g);
                let match = (matches && matches.join('')) || '';
                let valueFormatted = [];

                for (let i = 0, len = match.length; i < len; i += 4) {
                    valueFormatted.push(match.substring(i, i + 4));
                }

                newValue = valueFormatted.length
                    ? valueFormatted.join(' ')
                    : newValue;
                const newValueWithNoWhiteSpace = valueFormatted.length
                    ? valueFormatted.join('')
                    : newValue.replace(/\s+/g, '');

                let ibanError = null;
                if (newValueWithNoWhiteSpace.length > 34 || newValueWithNoWhiteSpace.length < 14) {
                    ibanError = [t('iban_iban_error_invalid')];
                } else if (
                    newValueWithNoWhiteSpace.length >= 2
                    && config?.accepted_prefix
                    && !config?.accepted_prefix.includes(newValueWithNoWhiteSpace.slice(0, 2))
                ) {
                    ibanError = [t('iban_iban_error_invalid_iban_prefix')];
                // Special rules for currency CHF
                } else if (currency === 'CHF') {
                    if (newValueWithNoWhiteSpace.startsWith('CH', 0)) {
                        setIsBicRequired(false);
                        ibanError = /CH[0-9A-Z]{2}3/g.test(newValueWithNoWhiteSpace)
                            ? [t('iban_iban_error_invalid_iban_prefix')]
                            : null;
                    } else {
                        setIsBicRequired(config?.collect_bic);
                    }
                }

                setErrors((prevState) => ({
                    ...prevState,
                    iban: ibanError,
                }));

                return newValue;
            },
            [t, currency, config?.collect_bic, config?.accepted_prefix],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));
            },
            [],
        );

        const onBlurFormField = React.useCallback(
            (fieldName) => (_event) => {
                setFormFieldBlurState((prevState) => ({
                    ...prevState,
                    [fieldName]: true,
                }));
            },
            [],
        );

        React.useEffect(
            () => {
                setIsBicRequired(config?.collect_bic);
            },
            [config?.collect_bic],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: formData.iban && !formData.holder && (!isBicRequired || formData.bic),
            }),
            [isBicRequired, formData],
        );

        return (
            <div id="wz-lyriapay__iban" className="wz-lyriapay__common__psp">
                <form noValidate onSubmit={submitForm}>
                    <div className="wz-lyriapay__common__psp__form">
                        <FormField
                            name="iban"
                            value={formData.iban}
                            onChange={onChangeFormField('iban')}
                            onBlur={onBlurFormField('iban')}
                            formatAndValidate={formatAndValidateIban}
                            label={t('iban_iban')}
                            errors={formFieldBlurState.iban && errors.iban}
                            required
                        />
                        {isBicRequired && (
                            <FormField
                                name="bic"
                                value={formData.bic}
                                onChange={onChangeFormField('bic')}
                                label={t('iban_bic')}
                                errors={errors.bic}
                                required
                            />
                        )}
                        <FormField
                            name="holder"
                            value={formData.holder}
                            onChange={onChangeFormField('holder')}
                            label={t('iban_holder')}
                            errors={errors.holder}
                            required
                        />
                        <div style={{width:'100%', display: 'inline-block'}}>
                            <InfoBlock
                                title={t('payment_card_input_warning_message_title')}
                                message={<Trans i18nKey="iban_holder_name_warning" />}
                            />
                        </div>
                    </div>
                    {!hidePayButton && (
                        <div className="wz-lyriapay__common__psp__submit">
                            <Button
                                disabled={
                                    !formData.iban
                                    || formData.iban?.length > 42
                                    || formData.iban?.length < 17
                                    || !formData.holder
                                    || errors?.iban?.length
                                    || (isBicRequired && !formData.bic)
                                }
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

Iban.propTypes = {
    /**
     * The specific configuration related to this payment method (IBAN).
     */
    config: PropTypes.object,
    /**
     * The ISO 4217 currency code.
     */
    currency: PropTypes.string,
    /**
     * The id of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string.isRequired,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

Iban.defaultProps = {
    config: {},
    currency: null,
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

Iban.displayName = 'Iban';

export default Iban;
